// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCecmou4hN7SmIyXItokNiBB0wIDAdzTNI",
  authDomain: "aloninja-8a90b.firebaseapp.com",
  projectId: "aloninja-8a90b",
  storageBucket: "aloninja-8a90b.appspot.com",
  messagingSenderId: "903138693368",
  appId: "1:903138693368:web:7c034240791ffb2c212b0d",
  measurementId: "G-88SR89RJBE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
export const auth = getAuth(app);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Google Auth Provider
export const googleProvider = new GoogleAuthProvider();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export default messaging;

const firebaseKey =
  "BDE5UHoL6Z9EppFz_kpBh0YUdREa11OGIuP64Dlf6VMJ-SMN1rvlhYtfs_15SFQjbfb5hlHSkYyUTfS-s5FiZqM";
export { firebaseKey };
