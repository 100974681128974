/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/lib/api";

export const getRoles = createAsyncThunk("role/fetch", async (filter) => {
  try {
    const response = await api.get("/api/roles", { params: filter });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getRoleOptions = createAsyncThunk("roleOptions/fetch", async () => {
  try {
    const response = await api.get("/api/roles/list");
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getPermissions = createAsyncThunk("permissions/fetch", async () => {
  try {
    const response = await api.get("/api/roles/permissions");
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

const initialState = {
  data: [],
  fetchLoading: false,
};

const roleSlide = createSlice({
  name: "role",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {
      return { ...state, fetchLoading: true };
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      return { ...state, ...action.payload, fetchLoading: false };
    });
    builder.addCase(getRoleOptions.pending, (state) => {
      return { ...state, fetchLoading: true };
    });
    builder.addCase(getRoleOptions.fulfilled, (state, action) => {
      return { ...state, roleOptions: action.payload, fetchLoading: false };
    });
    builder.addCase(getPermissions.pending, (state) => {
      return { ...state, fetchLoading: true };
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      console.log({ dsd: action.payload });
      return { ...state, rolePermissions: action.payload.data };
    });
  },
});

export default roleSlide.reducer;
