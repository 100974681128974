import React from "react";

export default function RingIcon({ width, height, fill = "none", ...props }) {
    return (
        <div {...props}>
            <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="30.5" cy="30" r="30" fill="#C2002F" />
                <path d="M30.5717 22.0695C27.7633 22.4935 25.5173 24.8129 25.1796 27.794L24.8348 30.8385C24.7491 31.595 24.4425 32.3069 23.9555 32.88C22.9221 34.0964 23.7608 36 25.3301 36H37.6699C39.2392 36 40.0779 34.0964 39.0445 32.88C38.5575 32.3069 38.2509 31.595 38.1652 30.8385L37.9373 28.8267M34.5 38C34.0633 39.1652 32.885 40 31.5 40C30.115 40 28.9367 39.1652 28.5 38M39.5 23C39.5 24.6569 38.1569 26 36.5 26C34.8431 26 33.5 24.6569 33.5 23C33.5 21.3431 34.8431 20 36.5 20C38.1569 20 39.5 21.3431 39.5 23Z" stroke="#FEFEFE" stroke-width="1.5" stroke-linecap="round" />
            </svg>
        </div>
    );
}
