import pattern from "@/lib/const/pattern";

export const validationRules = {
  required: {
    required: true,
  },
  phoneNumber: {
    pattern: pattern.phone,
    message: "Số điện thoại không đúng định dạng",
  },
  maxLengthInput: { max: 160, message: "Không nhập quá 160 ký tự" },
  minLengthPassword: { min: 8, message: "Tối thiểu 8 ký tự" },
  gmail: {
    type: "email",
    message: "Email không đúng định dạng",
  },
  emailRegex: {
    pattern: pattern.emailBefore,
    message: "Email không đúng định dạng",
  },
  maxLengthEmail: {
    max: 255,
    message: "Không nhập quá 255 ký tự",
  },
  regexPass: {
    pattern: pattern.password,
    message: "Mật khẩu phải chứa ít nhất 1 chữ hoa, 1 chữ thường, 1 số và 1 ký tự đặc biệt",
  },
};
