import React from "react";

export default function HistoryIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C7.29064 18 5.64935 17.5217 4.23062 16.6336C3.6528 16.2719 3.11762 15.8456 2.63567 15.3636C2.15297 14.8808 1.72614 14.3447 1.36416 13.7658C0.477504 12.3479 0 10.7079 0 9C0 8.72598 0.0122757 8.45331 0.0366864 8.18256C0.08628 7.63251 0.57239 7.2268 1.12244 7.2764C1.6725 7.32599 2.0782 7.8121 2.02861 8.36215C2.00958 8.57321 2 8.78595 2 9C2 10.3302 2.37066 11.6032 3.05992 12.7055C3.34164 13.156 3.67401 13.5735 4.04996 13.9495C4.42534 14.3249 4.84208 14.6569 5.29179 14.9384C6.39466 15.6287 7.6687 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C7.12864 2 5.38425 2.7394 4.09794 4.00204L5.49771 4.00341C6.05 4.00341 6.49771 4.45113 6.49771 5.00341C6.49771 5.51625 6.11167 5.93892 5.61433 5.99669L5.49771 6.00341H1.49631C0.983473 6.00341 0.560802 5.61737 0.503037 5.12004L0.49631 5.00341V1.00351C0.49631 0.451227 0.944025 0.00351119 1.49631 0.00351119C2.00915 0.00351119 2.43182 0.389551 2.48958 0.88689L2.49631 1.00351L2.49589 2.77846C4.1661 1.03158 6.49557 0 9 0ZM8.25 4C8.6295 4 8.94346 4.28233 8.99315 4.64827L9 4.75V9H11.25C11.664 9 12 9.336 12 9.75C12 10.1295 11.7177 10.4435 11.3517 10.4931L11.25 10.5H8.25C7.8705 10.5 7.55654 10.2177 7.50685 9.85173L7.5 9.75V4.75C7.5 4.336 7.836 4 8.25 4Z"
        fill="#B3B3B3"
      />
    </svg>
  );
}
