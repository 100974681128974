import { Card, ConfigProvider, Modal, Spin, notification, Button, Progress, Tooltip } from 'antd'
import React, { useState } from 'react'
import UploadControl from '../../components/upload'
import { ReactComponent as ExcelIcon } from "@/asset/img/excel.svg";
import api from '@/lib/api';
import { DownloadOutlined, CloseOutlined, ZoomInOutlined } from '@ant-design/icons'
import { useSignal } from '@/hooks'
import FileDownload from "js-file-download";

export default function Checkin() {
    const [progress, setProgress] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const [loading, setLoading] = useState(false); // loading for import
    const [file, setFile] = useState({
        "id": 586,
        "title": "import-checkin-template (1)",
        "url": "/uploads/default/import-checkin-template-1_3.xlsx",
        "thumbnail": null,
        "extension": "xlsx",
        "size": 17593,
        "type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "createDate": "2024-03-09T01:59:44.1681216Z"
    });

    const [isShow, setIsShow] = useState(false);
    const [isShowModel, setIsShowModel] = useState(false);
    const [importErrorURL, setImportErrorURL] = useState(null);
    const [result, setResult] = useState({
        data: {
            totalFailed: 0,
            totalSuccess: 0,
            total: 0,
        },
    });

    const [fileData, setFileData] = useState(null);

    const sinalR = useSignal();

    const importCheckinPending = (payload) => {
        const process = (payload.count / payload.total) * 100;
        setProgress(process.toFixed(1));
        if (payload.total != result.total) {
            setIsShowModel(true);
        }
    };

    const importCheckinFailed = (payload) => {
        setImportErrorURL(payload.path);

        setResult((prevState) => ({
            ...prevState,
            ...(prevState.data.totalFailed = payload.totalFailed),
            ...(prevState.data.totalSuccess = payload.totalSucceeded),
            ...(prevState.data.total = payload.total),
        }));
    };

    const handleZoomOut = () => {
        setIsShowModel(!isShowModel);
        setIsShow(!isShow);
    };

    sinalR({
        ImportCheckin: importCheckinPending,
        ImportCheckinFailed: importCheckinFailed,
    });


    const handleImportCheckin = async (data) => {
        setFile(data?.data);
        setOpen(true);
        try {
            var response = await api.post("/api/checkin/import-checkin", { path: data.data.url }, {
                responseType: "blob",
            });

            if (!response.data?.error) {
                setFileData({
                    blob: response.data,
                    fileName: "import-user-error.xlsx",
                });
            }

        } catch (error) {
            if (error.response.data.code === 1) {
                setIsShowError(true);
            }
            else {
                console.log(error)
                notification.error({
                    message: "Lỗi",
                    description: `${error?.response.data.message || error?.message} `
                })
            }
            setOpen(false);
        }
        finally {
            setLoading(false);
        }
    };
    const openNotification = () => {
        notification.error({
            message: "Thông báo lỗi",
            description: "Dữ liệu nhập vào phải là tệp có đuôi .xlsx",
        });
    };
    const beforeUpload = (file) => {
        const isExcel =
            file.type === "application/vnd.ms-excel" ||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isExcel) {
            openNotification();
            return isExcel;
        }
    };

    const handleDownloadFile = async () => {
        if (fileData) {
            FileDownload(fileData.blob, fileData.fileName);
        }
        else {
            notification.error({
                message: "Lỗi",
                description: "Không có tệp để tải xuống",
            });
        }
    }

    return (
        <div className='mt-4 mx-4'>
            <div className="ml-2 h-16 text-[22px] font-semibold">Nhập kết quả điểm danh</div>
            <Card>
                <UploadControl dragable={true} type="default"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onStartUpload={(e) => setLoading(true)}
                    onUploadSuccess={handleImportCheckin}
                    accept=".xlsx"
                    btnLoading={loading}
                />
            </Card>
            <div className="flex gap-1 justify-end mt-2 font-medium text-sm text-njgrey-500">
                <span>Tải file template để nhập thông tin nhanh hơn</span>
                <span className='text-primary cursor-pointer hover:text-njred-500' onClick={() => window.open("/TemplateExcel/checkin_template.xlsx?" + new Date())}><DownloadOutlined /> Tải file template</span>
            </div>

            <ConfigProvider
                theme={{
                    components: {
                        Modal: {
                            padding: 20,
                            paddingMD: 20,
                            paddingContentHorizontalLG: 20,
                        },
                    },
                }}>
                <Modal
                    maskClosable={false}
                    title="Lỗi File"
                    open={
                        isShowError
                    }
                    okButtonProps={{ style: { display: "none" } }}
                    cancelButtonProps={{ style: { display: "none" } }}
                    onCancel={() => setIsShowError(false)}
                >
                    <div className='flex gap-2 items-center mb-4 p-2 rounded-md border-dashed border border-njred-200 bg-njred-50'>
                        <ExcelIcon />
                        <div className=''>
                            <div className="text-black font-medium">
                                {file?.title}
                            </div>
                            <div>
                                {file.extension} |{" "}
                                {parseFloat((file.size / 1024).toFixed(2))} KB
                            </div>
                        </div>

                    </div>

                    <div className='text-njred-500'>
                        File tải lên không đúng định dạng. Vui lòng kiểm tra lại!
                    </div>
                </Modal>

                <Modal
                    maskClosable={false}
                    closable={() => setImportErrorURL(null)}
                    title="Nhập dữ liệu"
                    open={
                        isOpen
                    }
                    onOk={handleZoomOut}
                    onCancel={() => setOpen(false)}
                    cancelButtonProps={{ style: { display: "none" } }}
                    okText="Thu nhỏ"
                    cancelText="Đóng">
                    <Progress percent={progress} />
                    <div className="flex justify-center">
                        <p className="text-green-500">Thành công: {result.data.totalSuccess}</p>
                        <p className="text-primary ml-3 mr-3">Thất bại: {result.data.totalFailed}</p>
                        <p>Tổng: {result.data.total}</p>
                    </div>
                    {result.data.totalFailed > 0 ? (
                        <div className="w-full flex justify-center  mt-4">
                            <Tooltip title="Tải xuống file lỗi">
                                <Button
                                    type='primary'
                                    className="bg-primary w-15 h-10"
                                    onClick={handleDownloadFile}>
                                    <DownloadOutlined style={{ color: "white", fontSize: "26px" }} />
                                </Button>
                            </Tooltip>
                        </div>
                    ) : (
                        ""
                    )}
                </Modal>
            </ConfigProvider>

            {isShow && (
                <div
                    className=" border-primary fixed shadow-lg bg-slate-100 rounded-lg w-56 p-2"
                    style={{
                        right: "30px",
                        bottom: "20px",
                    }}>
                    <div className="flex justify-end mb-1">
                        <ZoomInOutlined
                            onClick={handleZoomOut}
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                        />
                        <CloseOutlined
                            onClick={() => setIsShow(false)}
                            style={{ fontSize: "16px", marginLeft: "5px", fontWeight: "bold" }}
                        />
                    </div>
                    <Progress percent={progress} />
                </div>
            )}

        </div>
    )
}