import React from "react";

export default function ResetPassIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#C2002F" />
      <path
        d="M31.5 21.75H16.5C16.0858 21.75 15.75 22.0858 15.75 22.5V33C15.75 33.4142 16.0858 33.75 16.5 33.75H31.5C31.9142 33.75 32.25 33.4142 32.25 33V22.5C32.25 22.0858 31.9142 21.75 31.5 21.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.625 21.75V18.375C20.625 17.4799 20.9806 16.6214 21.6135 15.9885C22.2465 15.3556 23.1049 15 24 15C24.8951 15 25.7535 15.3556 26.3865 15.9885C27.0194 16.6214 27.375 17.4799 27.375 18.375V21.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 28.875C24.6213 28.875 25.125 28.3713 25.125 27.75C25.125 27.1287 24.6213 26.625 24 26.625C23.3787 26.625 22.875 27.1287 22.875 27.75C22.875 28.3713 23.3787 28.875 24 28.875Z"
        fill="white"
      />
    </svg>
  );
}
