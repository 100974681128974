/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/lib/api";

export const getEnums = createAsyncThunk("enum/fetch", async () => {
  try {
    const response = await api.get("/api/enums");
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getOptions = createAsyncThunk("options/fetch", async () => {
  try {
    const response = await api.get("/api/options");
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

const appSlice = createSlice({
  name: "app",
  initialState: {
    enums: {},
    options: {},
    sidebarMini: true,
  },
  extraReducers: (builder) => {
    builder.addCase(getEnums.fulfilled, (state, action) => {
      state.enums = action.payload;
      return state;
    });
    builder.addCase(getOptions.fulfilled, (state, action) => {
      return {
        ...state,
        options: action.payload
      };
    });
  },
});

export default appSlice.reducer;
