import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { userCan } from ".";
import useCan from "./use-can";
const useMenu = (routes) => {
  const navigate = useNavigate();
  return routes
    .filter((x) => !x.hidden || x.children?.length > 0)
    .map((r, index) => {
      // if (r.children && r.children.length > 0 && !r.hidden) {
      //   const item = { key: index, label: r.icon, children: [] };
      //   r.children
      //     .filter((x) => !x.hidden && (!x.caps || useCan(x.caps)))
      //     .map((c, cIndex) => {
      //       item.children.push({
      //         key: r.path + "/" + c.path,
      //         onClick: () => navigate(r.path + "/" + c.path),
      //         label: c.title,
      //         icon: <span className="ant-menu-item-icon">{c.icon}</span>,
      //       });
      //     });
      //   return item;
      // }
      // if (r.hidden && r.children.filter((x) => !x.hidden).length > 0) {
      //   r.children
      //     .filter((x) => !x.hidden && (!x.caps || useCan(x.caps)))
      //     .map((c, cIndex) => {
      //       return {
      //         label: c.title,
      //         key: r.path + "/" + c.path,
      //         onClick: () => navigate(r.path + "/" + c.path),
      //         icon: <span className="ant-menu-item-icon">{c.icon}</span>,
      //       };
      //     });
      // }
      if (!r.hidden && (!r.caps || userCan(r.caps))) {
        return {
          label: r.title,
          onClick: () => navigate(r.path),
          icon: <span className="ant-menu-item-icon">{r.icon}</span>,
          key: r.path,
        };
      }
    });
};
export default useMenu;
