import { Button, Progress, Upload } from "antd";
import { useState } from "react";
import classnames from "classnames";
import { CloudUploadOutlined } from "@ant-design/icons";
import api from "@/lib/api";

const { Dragger } = Upload;

const UploadControl = ({
  children,
  showPercent = false,
  className,
  dragable,
  onUploadSuccess,
  onUploadFail,
  onStartUpload,
  options = {
    saveDb: true,
    path: "default",
  },
  btnLoading = false,
  ...props
}) => {
  const [uploadPercent, setUploadPercent] = useState(-1);

  const uploadProps = {
    name: "file",
    ...props,
    customRequest: async (opts) => {
      const { onSuccess, onError, file, onProgress } = opts;
      const fmData = new FormData();

      fmData.append("file", file);

      Object.entries(options).map(([key, value]) => {
        fmData.append(key, value);
      });

      try {
        if (onStartUpload) onStartUpload(file.name);
        var res = await api.post(
          "/api/attachments",
          fmData,
          {
            onUploadProgress: (event) => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              setUploadPercent(percent);
              onProgress({
                percent: (event.loaded / event.total) * 100,
              });
            },
          },
          file.uid,
        );

        console.log(res);
        setUploadPercent(100);
        setTimeout(() => setUploadPercent(-1), 500);
        if (onUploadSuccess) onUploadSuccess(res);
        return onSuccess(res, res);
      } catch (err) {
        if (onUploadFail) onUploadFail(err);
        console.log("Eroor: ", err);
        onError({ err });
      }
    },
  };

  const classNames = classnames("relative", className);
  return (
    <div className={classNames}>
      {dragable ? (
        <Dragger {...uploadProps} className="h-56 border-b-njred-400" disabled={btnLoading}>
          <CloudUploadOutlined style={{
            fontSize: '36px',
            fontWeight: 'normal',
            color: "#8F8F8F"
          }} />
          <p className="text-njgrey-400 font-normal text-sm">Click hoặc kéo tệp vào khu vực này để tải lên Excel</p>
          <Button type="primary" loading={btnLoading} className="bg-njred-400 my-3 text-sm text-white "><CloudUploadOutlined style={{
            fontSize: '14px',
            fontWeight: 'normal'
          }} />Tải lên</Button>
        </Dragger>
      ) : (
        <Upload {...uploadProps}>
          {children}
          {showPercent && uploadPercent > 0 && (
            <Progress
              className="absolute top-1/2 left-2 right-2 -translate-y-1/2"
              percent={uploadPercent}
            />
          )}
        </Upload>
      )}
    </div>
  );
};
export default UploadControl;