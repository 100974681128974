import React from "react";
import { Button, Modal } from "antd";
import ResetPassIcon from "@/components/icons/reset-pass-icon";

const ResetPassModal = ({ open, setOpen }) => {
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onCancel={handleCancel} width={335} footer={null} closable={false}>
        <div className="pt-6 px-5">
          <div className="flex justify-center mb-6">
            <ResetPassIcon />
          </div>
          <div className="text-center mb-6">
            Để thay đổi mật khẩu, vui lòng liên hệ với quản lý trực tiếp, cung cấp thông tin tài
            khoản để yêu cầu hỗ trợ đổi mật khẩu.
          </div>
        </div>
        <div className="flex text-center border-t p-4">
          <div className="flex-1 cursor-pointer font-medium" onClick={handleCancel}>
            Đóng
          </div>
          <div className="flex-1 text-primary cursor-pointer font-medium" onClick={handleCancel}>
            Đã hiểu
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ResetPassModal;
