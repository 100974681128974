import React, { useContext, useEffect, useMemo } from "react";
import { ConfigProvider, Layout } from "antd";
import AppContext, { AppProvider } from "@/context/app";
import { StringeeProvider } from "@/context/stringee";
import customComponents from "@/shared/custom-components";
import "dayjs/locale/vi";
import locale from "antd/locale/vi_VN";
import Content from "./content";
import { useLocation, useNavigate } from "react-router-dom";

const AdminLayout = () => {


  return (
    <AppProvider>
      <StringeeProvider>
        <ConfigProvider
          locale={locale}
          form={{
            validateMessages: {
              required: "Đây là trường bắt buộc",
            },
          }}
          theme={{
            components: customComponents,
            token: {
              colorPrimaryBg: "#FFF1F0",
            },
          }}>
          <Content />
        </ConfigProvider>
      </StringeeProvider>
    </AppProvider>
  );
};

export default AdminLayout;
