// import locale from "antd/es/date-picker/locale/vi_VN";
export default {
  Form: {
    marginLG: 12,
  },
  Input: {
    colorPrimary: "#C31424",
    controlOutline: "#E5E5E5",
    colorPrimaryActive: "#C31424",
    controlOutlineWidth: 1,
    colorPrimaryHover: "#C31424",
    borderRadius: 4,
  },
  Checkbox: {
    colorPrimary: "#C31424",
    colorPrimaryHover: "#C31424",
  },
  Select: {
    colorPrimary: "#C31424",
    colorPrimaryHover: "#C31424",
    borderRadius: 4,
    controlOutline: "#E5E5E5",
    colorFillTertiary: "#FFF1F0",
    // colorFillTertiary: "##FFF1F0"
  },
  Radio: {
    colorPrimary: "#C31424",
  },
  Button: {
    colorPrimary: "#C31424",
    borderRadius: 4,
    borderRadiusLG: 4,
    colorPrimaryHover: "#C31424",
    colorPrimaryActive: "#C31424",
    fontSizeLG: 14,
  },
  Pagination: {
    colorPrimaryHover: "#C31424",
    colorPrimary: "#C31424",
  },
  Table: {
    colorPrimary: "#C31424",
  },
  Collapse: {
    collapseHeaderPadding: 0,
    collapseContentPaddingHorizontal: 50,
  },
  Slider: {
    colorPrimaryBorder: "#000",
    colorBgContainer: "#000",
    colorPrimary: "#000",
  },
  Popover: {
    sizePopupArrow: 0,
  },
  DatePicker: {
    colorPrimaryHover: "#C31424",
    colorPrimary: "#C31424",
  },
  Modal: {
    padding: 0,
    paddingMD: 0,
    paddingContentHorizontalLG: 0,
    borderRadiusLG: 4,
  },
  // Notification: {
  //   paddingMD: 0,
  // },
};
