import React from "react";

export default function CallInIcon({ stroke, width, height, fill = "none", ...props }) {
  return (
    <div {...props}>
      <svg
        stroke={stroke}
        width={width}
        height={height}
        viewBox="0 0 10 10"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.33483 4.8507C3.72381 5.64595 4.36844 6.28765 5.16545 6.67301C5.22377 6.70064 5.28827 6.71261 5.35261 6.70775C5.41696 6.70288 5.47893 6.68134 5.53242 6.64524L6.70597 5.8627C6.75787 5.82809 6.81759 5.80697 6.87971 5.80127C6.94183 5.79556 7.00439 5.80544 7.06173 5.83001L9.25722 6.77094C9.3318 6.80262 9.39407 6.85768 9.43463 6.92782C9.4752 6.99797 9.49188 7.0794 9.48214 7.15984C9.41275 7.70285 9.14781 8.20194 8.73693 8.56367C8.32606 8.92539 7.79742 9.12496 7.25 9.12498C5.55924 9.12498 3.93774 8.45333 2.74219 7.25779C1.54665 6.06225 0.875 4.44074 0.875 2.74998C0.875029 2.20257 1.07459 1.67393 1.43632 1.26305C1.79805 0.852176 2.29714 0.587238 2.84014 0.517844C2.92058 0.508109 3.00201 0.524781 3.07216 0.565348C3.1423 0.605916 3.19737 0.66818 3.22905 0.742761L4.17079 2.94016C4.19515 2.997 4.20507 3.05898 4.19968 3.12058C4.19429 3.18218 4.17375 3.24149 4.13988 3.29324L3.36005 4.48479C3.32456 4.53839 3.30358 4.60028 3.29916 4.66441C3.29474 4.72855 3.30702 4.79274 3.33483 4.8507V4.8507Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
