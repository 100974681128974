import React, { useEffect, useRef, useState, useContext } from "react";
import CallIcon from "../icons/call-in-icon";
import VoipCall from "./calling";
import StringeeContext from "@/context/stringee";
import autoAnimate from "@formkit/auto-animate";
import { StringeeCallState } from "@/context/stringee";
import { useSelector } from "react-redux";

const statuses = {
  1: <p className="text-njred-400">Chưa kết nối</p>,
  2: <p className="text-njgrey-300">Đang kết nối</p>,
  3: <p className="text-green-400">Đã kết nối</p>,
};

export default function Stringee() {
  const [isShow, setIsShow] = useState(false);
  const parent = useRef(null);
  const account = useSelector((state) => state.account);

  useEffect(() => {
    autoAnimate(parent.current);
  }, []);

  const stringeeContext = useContext(StringeeContext);
  const { status, callState } = stringeeContext;

  useEffect(() => {
    if (callState == StringeeCallState.RINGING) setIsShow(true);
  }, [callState]);

  return (
    <div>
      {status && status === 3 && account.profile.allowStringeeCall && (
        // {false === 3 && (
        <div
          className="w-12 h-12 rounded-full bg-primary flex items-center justify-center cursor-pointer fixed shadow-sm"
          style={{
            right: "30px",
            bottom: "85px",
          }}
          onClick={() => setIsShow(!isShow)}>
          <CallIcon className="leading-none" width={25} height={25} fill={"#fff"} />
        </div>
      )}
      <div ref={parent}>{isShow && <VoipCall />}</div>
    </div>
  );
}
