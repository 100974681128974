import { Outlet } from "react-router";
import { ConfigProvider } from "antd";
import customComponents from "@/shared/custom-components";

export default function AuthLayout({ ...props }) {
  return (
    <ConfigProvider
      form={{
        validateMessages: {
          required: "Đây là trường bắt buộc",
        },
      }}
      theme={{
        components: customComponents,
        token: {
          colorPrimaryBg: "#FFF1F0",
        },
      }}>
      <Outlet />
    </ConfigProvider>
  );
}
