import { useSelector } from "react-redux";

export default function useCan(caps) {
  const account = useSelector((state) => state.account);
  // console.log({ account });
  if (!account.profile || !account.profile.roles) return false;

  if (account.profile.roles.some((x) => x.name == "Super Admin")) return true;

  const capArr = caps.split("|");

  return capArr.some(
    (cap) =>
      account.profile.caps?.includes(cap) ||
      account.profile.roles.some((r) => r.caps.includes(cap)),
  );
}