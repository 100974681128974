import StringeeContext from "@/context/stringee";
import React, { useContext, useEffect, useRef, useState } from "react";
import {StringeeCallState}  from "@/context/stringee"

export default function StopWatch({}) {
  const [time, setTime] = useState(0);
  const interval = useRef(null)

  const stringeeContext = useContext(StringeeContext);
  const { callState } = stringeeContext;


 

  useEffect(() => {
    if (callState == StringeeCallState.ANSWERED ) {
      interval.current = setInterval(() => {
        setTime((time)=>time + 1);
      }, 1000);
    } 
    if(StringeeCallState.ENDED == callState){
      clearInterval(interval.current)
    }
    if(StringeeCallState.INIT ===callState){
      setTime(0)
    }
   
  }, [callState]);
 if (callState != StringeeCallState.ENDED && callState != StringeeCallState.ANSWERED) {
   return null;
 }
  const formatTime = (time) => {
    const getSeconds = `0${time % 60}`.slice(-2);
    const minutes = `${Math.floor(time / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  return (
    <div>
      <div>
        <div className="text-center">
          <p>{formatTime(time)}</p>
        </div>
      </div>
    </div>
  );
}
