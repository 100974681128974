import React from "react";

const DepartmentIcon = ({ fill = "white" }) => {
    return (
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.75 0H11.25C11.6297 0 11.9435 0.282154 11.9932 0.648229L12 0.75V3H17.25C17.6642 3 18 3.33579 18 3.75V19.5C18 19.7761 17.7761 20 17.5 20H14.5C14.2239 20 14 19.7761 14 19.5V16H11V19.5C11 19.7761 10.7761 20 10.5 20H7.5C7.22386 20 7 19.7761 7 19.5V3.75C7 3.33579 7.33579 3 7.75 3H10.5V1.5H1.5V19.25C1.5 19.6642 1.16421 20 0.75 20C0.370304 20 0.0565091 19.7178 0.00684667 19.3518L0 19.25V0.75C0 0.370304 0.282154 0.0565091 0.648229 0.00684667L0.75 0ZM5 15V17H3V15H5ZM5 12V14H3V12H5ZM12 12H10V14H12V12ZM15 12H13V14H15V12ZM5 9V11H3V9H5ZM12 9H10V11H12V9ZM15 9H13V11H15V9ZM5 6V8H3V6H5ZM12 6H10V8H12V6ZM15 6H13V8H15V6ZM5 3V5H3V3H5Z"
          fill={fill}
        />
      </svg>
    );
  };

  
export default DepartmentIcon;
