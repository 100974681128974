import React from "react";

export default function HubIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49501 12.501L8.49426 19.999H5.49926L5.50001 12.501H8.49501ZM15.2547 11.001H11.7533C11.3391 11.001 11.0033 11.3368 11.0033 11.751V15.253C11.0033 15.6672 11.3391 16.003 11.7533 16.003H15.2547C15.6689 16.003 16.0047 15.6672 16.0047 15.253V11.751C16.0047 11.3368 15.6689 11.001 15.2547 11.001ZM14.504 12.501V14.503H12.503V12.501H14.504ZM6.16601 5.002H1.50101L1.50112 6.16674C1.50112 7.34763 2.37864 8.32356 3.51717 8.47801L3.67399 8.49393L3.83369 8.49931C5.06826 8.49931 6.07881 7.54019 6.16088 6.32644L6.16626 6.16674L6.16601 5.002ZM12.331 5.002H7.66601L7.66626 6.16674C7.66626 7.34763 8.54379 8.32356 9.68232 8.47801L9.83913 8.49393L9.99883 8.49931C11.2334 8.49931 12.244 7.54019 12.326 6.32644L12.3314 6.16674L12.331 5.002ZM18.498 5.002H13.833L13.8338 6.16674C13.8338 7.34763 14.7113 8.32356 15.8498 8.47801L16.0066 8.49393L16.1663 8.49931C17.4009 8.49931 18.4115 7.54019 18.4935 6.32644L18.4989 6.16674L18.498 5.002ZM7.06063 1.5H4.32601L2.46901 3.502H6.44563L7.06063 1.5ZM11.3686 1.5H8.63063L8.01463 3.502H11.9846L11.3686 1.5ZM15.673 1.5H12.9386L13.5536 3.502H17.53L15.673 1.5ZM0.201289 3.74193L3.45018 0.239913C3.56845 0.112432 3.72652 0.0308129 3.89668 0.00714779L4.00001 0H16C16.1739 0 16.3412 0.0603719 16.4743 0.169024L16.5498 0.239913L19.8221 3.76824L19.8528 3.80714C19.9545 3.94281 20.0004 4.09665 20.0004 4.24775L19.9989 6.16674C19.9989 7.16092 19.6204 8.06668 18.9996 8.74776L18.9988 19.25C18.9988 19.6297 18.7166 19.9435 18.3505 19.9932L18.2488 20L9.99526 19.999L9.99596 11.751C9.99596 11.3368 9.66017 11.001 9.24596 11.001H4.75001C4.3358 11.001 4.00001 11.3368 4.00001 11.751L3.99926 19.999L1.75126 20C1.37156 20 1.05777 19.7178 1.0081 19.3518L1.00126 19.25L1.00047 8.74776C0.420965 8.112 0.0525613 7.28045 0.00610447 6.36396L0.00111771 6.16674L0.000920296 4.29097C-0.00715016 4.15366 0.0225032 4.01238 0.096853 3.88313L0.146634 3.807L0.201289 3.74193Z"
        fill="white"
      />
    </svg>
  );
}
