import React, { createContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { isEqual } from "lodash";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const connectRef = useRef(null);

  useEffect(() => {
    const createHubConnection = async () => {
      connectRef.current = new HubConnectionBuilder()
        .withUrl("/messagehub", {
          headers: { 'x-token': `${localStorage.getItem("access_token")}` }
        })
        .withAutomaticReconnect([0, 100, 200, 500, 800, 1000, 2000, 3000, null])
        .configureLogging(LogLevel.Information)
        .build();
      try {
        await connectRef.current.start();
        connectRef.current.invoke("Join");
        //Invoke method defined in server to add user to a specified group
      } catch (err) {
        console.log(err);
        console.error("Error while starting connection: ", err.message);
        console.error("Stack trace: ", err.stack);
      }
    };
    createHubConnection();
  }, []);


  const account = useSelector((state) => state.account);
  const app = useSelector((state) => state.app, isEqual);

  return (
    <AppContext.Provider
      value={{
        collapsed,
        setCollapsed,
        profile: account.profile,
        options: app.options,
        messageHub: connectRef?.current,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
