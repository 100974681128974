/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/lib/api";

export const getDepartments = createAsyncThunk("department/fetch", async (filter) => {
  try {
    const response = await api.get("/api/departments", { params: filter });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const createDepartment = createAsyncThunk("department/create", async (data) => {
  try {
    const response = await api.post("/api/departments", data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const updateDepartment = createAsyncThunk("department/update", async (data) => {
  try {
    const response = await api.put(`/api/departments/${data.id}`, data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const deleteDepartment = createAsyncThunk("department/delete", async (id) => {
  try {
    const response = await api.delete(`/api/departments/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getDepartment = createAsyncThunk("department/get", async (id) => {
  try {
    const response = await api.get(`/api/departments/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getUsersDepartment = createAsyncThunk("department/getUsers", async (filter) => {
  try {
    const response = await api.get(`/api/departments/users`, { params: filter });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const lockDepartment = createAsyncThunk("department/lock", async (data) => {
  try {
    const response = await api.put(`/api/departments/${data.id}/lock`, data);
    return response.data;
  } catch (er) {
    console.log(er)
  }
})

const initialState = {
  data: [],
  fetchLoading: false,
  pagination: {},
  creating: false,
  details: {},
  users: {},
};

const accountSlide = createSlice({
  name: "department",
  initialState,
  reducers: {
    cleanupDetails: (state) => {
      state.details = {};
      return state;
    },
    cleanup: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartments.pending, (state) => {
      return { ...state, fetchLoading: true };
    });
    builder.addCase(getDepartments.fulfilled, (state, action) => {
      return { ...state, ...action.payload, fetchLoading: false };
    });
    builder.addCase(createDepartment.pending, (state, action) => {
      return { ...state, ...action.payload, creating: true };
    });
    builder.addCase(createDepartment.fulfilled, (state) => {
      return { ...state, creating: false };
    });
    builder.addCase(createDepartment.rejected, (state) => {
      return { ...state, creating: false };
    });
    builder.addCase(deleteDepartment.pending, (state, action) => {
      return { ...state, ...action.payload, deleting: true };
    });
    builder.addCase(deleteDepartment.fulfilled, (state) => {
      return { ...state, deleting: false };
    });
    builder.addCase(deleteDepartment.rejected, (state) => {
      return { ...state, deleting: false };
    });
    builder.addCase(updateDepartment.pending, (state, action) => {
      return { ...state, ...action.payload, updating: true };
    });
    builder.addCase(updateDepartment.fulfilled, (state) => {
      return { ...state, updating: false };
    });
    builder.addCase(updateDepartment.rejected, (state) => {
      return { ...state, updating: false };
    });
    builder.addCase(getDepartment.pending, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(getDepartment.fulfilled, (state, action) => {
      return { ...state, details: action.payload };
    });
    builder.addCase(getDepartment.rejected, (state) => {
      return { ...state };
    });
    builder.addCase(getUsersDepartment.pending, (state) => {
      return { ...state };
    });
    builder.addCase(getUsersDepartment.fulfilled, (state, action) => {
      return { ...state, users: action.payload };
    });
    builder.addCase(lockDepartment.pending, (state) => {
      return { ...state, locking: true };
    });
    builder.addCase(lockDepartment.fulfilled, (state) => {
      return { ...state, locking: false };
    });
    builder.addCase(lockDepartment.rejected, (state) => {
      return { ...state, locking: false };
    });
  },
});

export const { cleanupDetails, cleanup } = accountSlide.actions;
export default accountSlide.reducer;
