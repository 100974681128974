import axios from "axios";
import qs from "qs";
import { loggout } from "@/reducers/account";
import { store } from "../index";

const api = axios.create({
    paramsSerializer: {
        serialize: (params) => qs.stringify(params),
    },
});

api.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
        req.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
    }
    req.headers["x-hour-offset"] = (new Date().getTimezoneOffset() / 60) * -1;
    req.headers["x-web"] = "true";
    req.headers.UserId = localStorage.getItem("UserId");
    req.headers.Accept = "application/json";
    return req;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            store.dispatch(loggout());
            return Promise.reject(error);
        }
        //error validate
        if (error.response?.data && error.response.status == 422) {
            return { data: { error: error.response?.data } };
        }
        return Promise.reject(error);
    },
);

export default api;
