import { Input, Form, notification, Button, message } from "antd";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import Logo from "../../asset/img/logo.png";
import CallBtn from "./call-btn";
import StringeeContext, { StringeeCallState } from "../../context/stringee";
import DefaultUser from "@/components/icons/default-user-icon";
import StopWatch from "./stop-watch";
import pattern from "@/lib/const/pattern";
import { RedoOutlined } from "@ant-design/icons";
import { getOptions } from "@/reducers/app";
import { useDispatch } from "react-redux";

const VoipCall = ({ ...props }) => {
  //số gọi đi
  const [phone, setPhone] = useState();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const stringeeContext = useContext(StringeeContext);
  const {
    callState,
    makeCall,
    hangupCall,
    answerCall,
    isCallOut,
    rejectCall,
    phoneNumber,
    stopCall,
  } = stringeeContext;

  const handleEnded = () => {
    if (isCallOut) {
      hangupCall();
    } else {
      if (StringeeCallState.ANSWERED == callState) {
        hangupCall();
      } else {
        stopCall();
      }
    }
  };
  const handleDisabled = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  };

  useEffect(() => {
    if (callState == StringeeCallState.ENDED) {
      setPhone(null);
    }
  }, [callState]);

  const handleGetOption = () => {
    setLoading(true);
    try {
      dispatch(getOptions());
      message.success("Successfully");
    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-64 shadow-njgrey-300 shadow-md bg-white  rounded-lg fixed bottom-40 right-5">
      <div className="w-full  bg-primary p-5 rounded-lg  text-center  relative">
        <Button
          className="absolute bg-[#FFCDD2] top-5 right-5"
          size="small"
          type="primary"
          loading={loading}
          onClick={handleGetOption}>
          <RedoOutlined className="text-white" />
        </Button>
        <div className="items-center flex justify-center">
          <img src={Logo} />
        </div>
        <p className="text-1xl font-bold text-white mt-5">Alo Ninja</p>
      </div>
      <div className="p-3">
        <div>
          {[StringeeCallState.INIT].includes(callState) && (
            <div className="py-3">
              <Form>
                <p className="font-semibold">
                  Nhập số điện thoại (<span className="text-primary">*</span>)
                </p>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: pattern.number,
                      message: "Số điện thoại không đúng định dạng",
                    },
                  ]}>
                  <Input onInput={(e) => setPhone(e.target.value)} value={phone} />
                </Form.Item>
              </Form>
            </div>
          )}

          {/* bắt đầu cuộc gọi */}
          {[
            StringeeCallState.CALLING,
            StringeeCallState.RINGING,
            StringeeCallState.ENDED,
            StringeeCallState.ANSWERED,
          ].includes(callState) && (
              <div className="text-center flex flex-col items-center mb-5">
                <p className="mb-5 font-semibold">{phoneNumber}</p>
                <DefaultUser />
              </div>
            )}

          {/* Kết thúc cuộc gọi */}
          {StringeeCallState.ENDED === callState && (
            <div className="text-center text-njred-500">Kết thúc cuộc gọi</div>
          )}
          {/* Máy bận */}
          {StringeeCallState.BUSY === callState && (
            <div className="text-center text-njred-500">Máy bận</div>
          )}

          {/* Đang trả lời */}
          <div className="flex justify-center mb-5">
            <StopWatch />
          </div>
        </div>
        <div>
          <div>
            {/* cuộc gọi chưa cúp máy */}
            {/* btn-green : out, btn-red: in*/}
            <div className="items-center justify-center flex">
              {([StringeeCallState.INIT].includes(callState) ||
                (StringeeCallState.RINGING == callState && !isCallOut)) && (
                  <CallBtn
                    disabled={disabled}
                    type="out"
                    onClick={() => {
                      callState === StringeeCallState.INIT ? makeCall(phone) : answerCall(),
                        handleDisabled();
                    }}
                  />
                )}
              {[
                StringeeCallState.CALLING,
                StringeeCallState.RINGING,
                StringeeCallState.ANSWERED,
              ].includes(callState) && <CallBtn type="in" onClick={handleEnded} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoipCall;
