import { ReactComponent as Dashboard } from "@/asset/img/icons/dashboard.svg";
import { ReactComponent as Avatar } from "@/asset/img/icons/avatar.svg";

const icons = {
  Dashboard: <Dashboard />,
  Avatar: <Avatar />,
};

const Icon = ({ name, ...props }) => {
  const icon = icons[name];
  return <div {...props}>{icon}</div>;
};

export default Icon;
