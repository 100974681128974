/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/lib/api";

export const getUsers = createAsyncThunk("user/fetch", async (filter, options) => {
  try {
    const response = await api.get("/api/users", { params: filter, ...options });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const createUser = createAsyncThunk("user/create", async (data) => {
  try {
    const response = await api.post("/api/users", data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getUser = createAsyncThunk("user/get", async (id) => {
  try {
    const response = await api.get(`/api/users/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const deleteUser = createAsyncThunk("user/delete", async (id) => {
  try {
    const response = await api.delete(`/api/users/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const updateUser = createAsyncThunk("user/update", async (data) => {
  try {
    const response = await api.put(`/api/users/${data.id}`, data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});
export const updateAvatar = createAsyncThunk("user/updateAvatar", async ({ id, url }) => {
  try {
    const response = await api.put("/api/account/avatar/" + id, { url });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const lockUser = createAsyncThunk("user/lock", async (data) => {
  try {
    const response = await api.put(`/api/users/${data.id}/lock`, data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const updatePass = createAsyncThunk("user/update-password", async (data) => {
  try {
    const response = await api.put(`/api/users/reset-password/${data.id}`, data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

const initialState = {
  data: [],
  fetchLoading: false,
  pagination: {},
};

const accountSlide = createSlice({
  name: "user",
  initialState,
  reducers: {
    cleanup: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      return { ...state, fetchLoading: true };
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      return { ...state, ...action.payload, fetchLoading: false };
    });
    builder.addCase(getUser.pending, (state) => {
      return { ...state, isLoadingDetails: true };
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      return { ...state, userDetails: action.payload, isLoadingDetails: false };
    });
    builder.addCase(getUser.rejected, (state) => {
      return { ...state, isLoadingDetails: false };
    });
    builder.addCase(createUser.pending, (state) => {
      return { ...state, creating: true };
    });
    builder.addCase(createUser.fulfilled, (state) => {
      return { ...state, creating: false };
    });
    builder.addCase(createUser.rejected, (state) => {
      return { ...state, creating: false };
    });
    builder.addCase(deleteUser.pending, (state) => {
      return { ...state, deleting: true };
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      return { ...state, deleting: false };
    });
    builder.addCase(deleteUser.rejected, (state) => {
      return { ...state, deleting: false };
    });
    builder.addCase(updateUser.pending, (state) => {
      return { ...state, updating: true };
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      return { ...state, updating: false };
    });
    builder.addCase(updateUser.rejected, (state) => {
      return { ...state, updating: false };
    });
    builder.addCase(updatePass.pending, (state) => {
      return { ...state, updatingPass: true };
    });
    builder.addCase(updatePass.fulfilled, (state) => {
      return { ...state, updatingPass: false };
    });
    builder.addCase(updatePass.rejected, (state) => {
      return { ...state, updatingPass: false };
    });
    builder.addCase(lockUser.pending, (state) => {
      return { ...state, locking: true };
    });
    builder.addCase(lockUser.fulfilled, (state) => {
      return { ...state, locking: false };
    });
    builder.addCase(lockUser.rejected, (state) => {
      return { ...state, locking: false };
    });
    builder.addCase(updateAvatar.fulfilled, (state, { meta }) => {
      const index = state.data.findIndex((x) => meta.arg.id == x.id);
      if (index >= 0) {
        state.data[index].avatar = meta.arg.url;
      }
      return state;
    });
  },
});

export const { cleanup } = accountSlide.actions;
export default accountSlide.reducer;
