import AppContext from "@/context/app";
import { useContext, useEffect } from "react";

export default function useSignalR() {
  const useSignalREffect = (
    events
  ) => {
    const { messageHub } = useContext(AppContext)

    useEffect(() => {
      messageHub?.on("message", ({ name,payload }) => {
        events[name]?.(payload)
      })
    }, [messageHub]);
  };

  return useSignalREffect;
}