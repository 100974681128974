import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import api from "@/lib/api";


export const getToken = createAsyncThunk("stringee/fetch", async (data) => {
  try {
    const response = await api.get("/api/stringee/token", { params: data });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const logoutStringee = createAction("stringee/logout");

const initialState = {
  fetchLoading: false,
  clientToken: null,
  restToken: null,
};

const stringeeSlide = createSlice({
  name: "stringee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getToken.fulfilled, (state, action) => {
      if (action.meta.arg?.isRest) {
        return { ...state, restToken: action.payload };
      }
      return { ...state, clientToken: action.payload };
    });
    builder.addCase(logoutStringee, () => {
      return initialState;
    });
  },
});
export default stringeeSlide.reducer;
