import { useDispatch, useSelector } from "react-redux";
import { getToken } from "@/reducers/stringee";
import { createContext, useRef, useState, useEffect, forwardRef, useMemo } from "react";
import { notification } from "antd";
import _, { isBuffer, set } from "lodash";
import api from "@/lib/api";

export const StringeeCallState = {
  INIT: 0,
  CALLING: 1,
  RINGING: 2,
  ANSWERED: 3,
  CONNECTED: 4,
  BUSY: 5,
  ENDED: 6,
};

const StringeeContext = createContext();

export const StringeeProvider = ({ children, ...props }) => {
  const stringeeClient = useRef(null);
  const call = useRef(null);
  const remoteVideo = useRef(null);
  const dispatch = useDispatch();
  //chuông
  const audio = useRef();

  //trạng thái kết nối
  const [status, setStatus] = useState(1);
  const [suport, setSuport] = useState();
  //trạng thái cuộc gọi
  const [callState, setCallState] = useState(StringeeCallState.INIT);
  //số gọi đến
  const [phoneNumber, setPhoneNumber] = useState();
  console.log("phoneNumber", phoneNumber)

  //isCallOut = true => app to phone, false => phone to app
  const [isCallOut, setIsCallOut] = useState(false);
  const [commingCall, setCommingCall] = useState(null);

  const options = useSelector((state) => state.app.options);
  const stringee = useSelector((state) => state.stringee.clientToken);
  const profile = useSelector((state) => state.account.profile);
  const [hasIncomingCall, setHasIncomingCall] = useState(false);
  const [logs, setLogs] = useState({
    hotline: null,
    userName: null,
    provider: null,
    data: null,
  });

  useEffect(() => {
    if (!stringee?.accessToken) dispatch(getToken());
  }, []);

  useEffect(() => {
    if (StringeeUtil.isWebRTCSupported()) {
      const STRINGEE_SERVER_ADDRS = ["wss://v1.stringee.com:6899/", "wss://v2.stringee.com:6899/"];
      stringeeClient.current = new StringeeClient(STRINGEE_SERVER_ADDRS);
      // stringeeClient.current = new StringeeClient();
      settingClientEvents();
      stringeeClient.current.connect(stringee?.accessToken);
    }
  }, [stringee]);

  const settingClientEvents = () => {
    stringeeClient.current.on("connect", () => {
      console.log("connected to StringeeServer");
      setStatus(3);
    });
    stringeeClient.current.on("authen", (res) => {
      if (res.r == 0) {
        setSuport({ suport: true });
      }
      if (res.r == 1) {
        console.log(res.message);
      }
      console.log("authen: ", res);
    });

    stringeeClient.current.on("disconnect", () => {
      console.log("disconnected");
      setStatus(1);
    });

    //client trả về khi có cuộc gọi đến
    stringeeClient.current.on("incomingcall", (incomingcall) => {
      console.log("--------", incomingcall);
      call.current = incomingcall;
      settingCallEvents();

      call.current.ringing((res) => {
        console.log("ringing res", res);
      });

      audio.current.play();

      setCallState(StringeeCallState.RINGING);
      setPhoneNumber(incomingcall.fromNumber);
      setIsCallOut(false);
    });

    stringeeClient.current.on("otherdeviceauthen", (data) => {
      console.log("otherdeviceauthen: ", data);
    });

    stringeeClient.current.on("requestnewtoken", function () {
      //please get new access_token from YourServer and call:
      dispatch(getToken());
    });
  };

  const getNetworkProvider = (phoneNumber) => {
    // Biểu thức chính quy để kiểm tra số điện thoại
    var pattern =
      /^(0|84|\+84)(32|33|34|35|36|37|38|39|96|97|98|89|90|91|88|93|94|70|76|77|78|79|81|82|83|84|85|86|56|58)([0-9]{7})$/;

    if (pattern.test(phoneNumber)) {
      var match = phoneNumber.match(pattern);
      var networkCode = match[2]; // Thay đổi từ match[1] thành match[2]

      if (
        ["32", "33", "34", "35", "36", "37", "38", "39", "96", "97", "98", "86"].includes(
          networkCode,
        )
      ) {
        return "viettel";
      } else if (["81", "82", "83", "84", "85", "88", "91", "94"].includes(networkCode)) {
        return "vinaPhone";
      } else if (["70", "76", "77", "78", "79", "89", "90", "93"].includes(networkCode)) {
        return "mobiFone";
      } else {
        return "other";
      }
    } else {
      return "other";
    }
  };

  const cacheCall = async (uniqueId) => {
    try {
      await api.post("/api/stringee/create-cache", {
        uniqueId: uniqueId,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Lỗi kết nối",
        description: "Không thể thực hiện cuộc gọi!",
      });
    }

  };

  // event gọi
  const settingCallEvents = () => {
    call.current.on("addremotestream", (stream) => {
      console.log("addRemoteStream", stream);
      remoteVideo.current.srcObject = null;
      remoteVideo.current.srcObject = stream;
    });

    call.current.on("addlocalstream", (stream) => {
      console.log("addLocalStream", stream);
    });

    call.current.on("signalingstate", (state) => {
      console.log("signalingstate", state);
      setCallState(state.code);

      if (state?.sipCode === 603 || state?.sipCode === 403 || state?.sipCode === 480) {
        setLogs((logs) => ({ ...logs, data: state }));
      }

      if (state.code != StringeeCallState.RINGING) audio.current.pause();

      /*
      code = 0, Được sử dụng để chỉ định lời mời cuộc gọi được gửi đến Stringee Server.
    + code = 1, Được sử dụng để xác định call đang đổ chuông.
    + code = 2, Được sử dụng để xác định người được gọi trả lời cuộc gọi.
    + code = 3, Được sử dụng để xác định người được gọi từ chối cuộc gọi.
    + code = 4, Được sử dụng để xác định cuộc gọi đã kết thúc.
    */
    });
    call.current.on("mediastate", (state) => {
      console.log("mediastate ", state);
      /*
      + code = ​​0, Được sử dụng để chỉ định phương tiện cuộc gọi được kết nối.
      + code = ​​1, Được sử dụng để chỉ định phương tiện cuộc gọi bị ngắt kết nối.*/
    });

    call.current.on("info", (info) => {
      console.log("on info", info);
    });

    call.current.on("otherdevice", (data) => {
      console.log("on otherdevice:" + JSON.stringify(data));
    });
  };

  const makeCall = async (phone) => {
    const uniqueId = Date.now();

    if (!window.navigator.onLine) {
      notification.error({
        message: "Lỗi kết nối",
        description: "Không có kết nối Internet. Vui lòng thử lại!",
      });
      return;
    }
    await cacheCall(uniqueId);


    if (!phone || phone === "") {
      return;
    }

    //phone is number
    if (isNaN(phone)) {
      console.log("phone is not number")
      return;
    }

    // if (phone.startsWith("0")) {
    //   phone = "84" + phone.substring(1);
    // } else if (phone.startsWith("+")) {
    //   phone = phone.substring(1);
    // } else if (!phone.startsWith("84")) {
    //   phone = "84" + phone;
    // }

    if (phone.startsWith("0")) {
      phone = "84" + phone.substring(1);
    }
    else if (phone.startsWith("+")) {
      phone = phone.substring(1);
    }
    else if (!phone.startsWith("84")) {
      phone = "84" + phone;
    }

    // if (phone.length !== 11) {
    //   return;
    // }

    phone = phone; //convert to number
    setPhoneNumber(phone);

    if (stringee.accessToken) {
      console.debug("startCall", phone, stringeeClient.current);

      //lấy số đầu tiên trong ds sdt gọi đi
      var hotline = "";

      const networkProvider = getNetworkProvider(phone);

      const hotlines = options[networkProvider];
      if (!hotlines) {
        hotline = options.stringeeHotlines[0];
      } else {
        hotline = _.sample(hotlines.split("\n"));
      }


      call.current = new StringeeCall(
        stringeeClient.current,
        hotline.replace("+", ""),
        phone.replace("+", ""),
        false,
      );
      call.current.custom = JSON.stringify({
        call_setting: { to_type: "external", channel: "voice", uniqueId: uniqueId },
      });

      //isComingCall = true cuộc gọi đến , false cuộc gọi đi
      //isAnswered kiểm tra cuộc gọi có được trả lời
      console.log(call.current.custom);
      setIsCallOut(true);
      settingCallEvents();

      //chạy đến đây thì ngừng.ko vào makecall
      console.log("setting done", call.current);

      call.current.makeCall((res) => {
        console.log("make call callback: " + JSON.stringify(res));
        setCallState(callState);
        setLogs((logs) => ({ ...logs, hotline: hotline, userName: profile?.fullName, provider: networkProvider, phone: phone }));
      });
    }
  };

  const answerCall = () => {
    console.log("answer");
    call.current.answer((res) => {
      console.log("answer res", res);
      setCallState(StringeeCallState.ANSWERED);
    });
  };

  const ResetAll = () => {
    const timer = setTimeout(() => {
      if (hasIncomingCall) {
        setHasIncomingCall(false);
      } else if (callState == StringeeCallState.BUSY || callState == StringeeCallState.ENDED) {
        setPhoneNumber(null);
        setCallState(StringeeCallState.INIT);
      }
    }, 3000);

    return () => clearTimeout(timer);
  };

  const hangupCall = () => {
    setCallState(StringeeCallState.CALLING);
    call.current.hangup((res) => {
      console.log("hangup res", res);
      setCallState(StringeeCallState.ENDED);
      ResetAll();
    });
  };

  const rejectCall = () => {
    call.current.reject((res) => {
      console.log("reject res", res);
      setCallState(StringeeCallState.ENDED);
      ResetAll();
    });
  };
  // console.log("test", call.current?.client.accessToken, call.current?.callId);

  const stopCall = async () => {
    const data = await api.post("/api/stringee/stop-call", {
      callId: call.current.callId,
    });
    console.log(data);
  };


  const logErrorToLark = async () => {
    console.log("send", logs)
    fetch("https://open-sg.larksuite.com/anycross/trigger/callback/YTZlMTMwOGE5ZTE4ZTZjZDQ0YTBkY2Q5YTI3MDI5NjQw", {
      method: "post",
      mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(logs)
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);
    }
    ).finally(() => {
      setLogs();
    });
  };

  useEffect(() => {
    if (!logs?.data) return;
    logErrorToLark();
  }, [logs])


  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasIncomingCall) {
        setHasIncomingCall(false);
      } else if (callState == StringeeCallState.BUSY || callState == StringeeCallState.ENDED) {
        setPhoneNumber(null);
        setCallState(StringeeCallState.INIT);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [callState, hasIncomingCall]);

  return (
    <StringeeContext.Provider
      value={{
        makeCall,
        answerCall,
        hangupCall,
        rejectCall,
        stopCall,
        callState,
        stringee,
        status,
        isCallOut,
        phoneNumber,
      }}>
      <VideoComp ref={remoteVideo}>
        {children}
        <audio ref={audio} />
      </VideoComp>
    </StringeeContext.Provider>
  );
};

export default StringeeContext;

const VideoComp = forwardRef(({ children }, remoteVideo) => {
  return (
    <div>
      <video id="remoteVideo" ref={remoteVideo} playsInline autoPlay style={{ display: "none" }} />
      {children}
    </div>
  );
});
