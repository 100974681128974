import { combineReducers } from "@reduxjs/toolkit";
import app from "./app";
import account, { blacklist as accountBlacklist } from "./account";
import user from "./user";
import hub from "./hub";
import department from "./department";
import stringee from "./stringee";
import role from "./role";

export default combineReducers({
  app,
  account,
  user,
  hub,
  department,
  stringee,
  role
});
export const blacklist = [
  ...accountBlacklist
]