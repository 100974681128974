import React from "react";

const EmployeeIcon = ({ fill = "#B3B3B3" }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.25 0C1.45507 0 0 1.45507 0 3.25V11.75C0 13.5449 1.45507 15 3.25 15H11.75C13.5449 15 15 13.5449 15 11.75V3.25C15 1.45507 13.5449 0 11.75 0H3.25ZM9.75 4.5C9.75 5.74264 8.74264 6.75 7.5 6.75C6.25736 6.75 5.25 5.74264 5.25 4.5C5.25 3.25736 6.25736 2.25 7.5 2.25C8.74264 2.25 9.75 3.25736 9.75 4.5ZM10.5 7.75C11.3284 7.75 12 8.42157 12 9.25V9.75C12 10.2457 11.9136 10.61 11.7461 10.9106C11.5779 11.2127 11.3158 11.4724 10.9277 11.7393C10.1952 12.2429 9.07024 12.5 7.5 12.5C6.17974 12.5 5.05475 12.2429 4.25982 11.7393C3.84149 11.4743 3.53039 11.2158 3.32189 10.9097C3.11722 10.6092 3 10.2459 3 9.75V9.25C3 8.42157 3.67157 7.75 4.5 7.75H10.5Z"
        fill={fill}
      />
      <path
        d="M3.01089 16C3.58843 16.902 4.59938 17.5 5.74999 17.5H12.25C15.1495 17.5 17.5 15.1494 17.5 12.25V5.74996C17.5 4.59935 16.9021 3.5884 16 3.01086V12.25C16 14.321 14.3211 16 12.25 16H3.01089Z"
        fill={fill}
      />
    </svg>
  );
};

export default EmployeeIcon;
