import React, { useState } from "react";
import { Modal } from "antd";
import RingIcon from "../../components/icons/ring-icon";

export const NotiModal = ({ children, noti, onClose }) => {
    return (
        <>
            <Modal open={!!noti}
                closeIcon={null}
                width={335} footer={null} closable={null}>
                <div className="pt-6 px-5">
                    <div className="flex justify-center mb-6">
                        <RingIcon />
                    </div>
                    <div className="text-center mb-6">
                        {noti || "Tài khoản của bạn không tồn tại trên hệ thống vui lòng kiểm tra lại!"}
                    </div>
                </div>
                <div className="flex text-center border-t p-4">
                    <div className="flex-1 cursor-pointer font-medium text-[#C2002F]" onClick={onClose}>
                        Xác nhận
                    </div>
                </div>
            </Modal>
        </>
    );
};
