import classNames from "classnames";
import CallInNew from "../icons/call-in-new";
import { Button } from "antd";

export default function CallBtn({ type, className, ...props }) {
  const classes = classNames(
    "w-12 h-12 rounded-full flex items-center justify-center cursor-pointer shadow-sm  mx-3 border-none",
    className,
    {
      "bg-green-500 hover:bg-green-600": type == "out",
      "bg-njred-500 hover:bg-njred-600 rotate-[135deg]": type == "in",
    },
  );
  return (
    <div className={classes} {...props}>
      <CallInNew width={25} height={25} />
    </div>
  );
}
