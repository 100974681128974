import { Modal } from "antd";
import React from "react";
import ResetPassIcon from "@/components/icons/reset-pass-icon";

const NotiLogout = ({ onClose }) => {

    return (
        <>
            <Modal open={true} onCancel={onClose} width={335} footer={null} closable={false} maskClosable={false}>
                <div className="pt-6 px-5">
                    <div className="flex justify-center mb-6">
                        <ResetPassIcon />
                    </div>
                    <div className="text-center mb-6">
                        Email đăng nhập của bạn đã thay đổi, vui lòng liên hệ HRBP để lấy thông tin đăng nhập!
                    </div>
                </div>
                <div className="flex text-center border-t p-4">
                    <div className="flex-1 cursor-pointer font-medium" onClick={onClose}>
                        Đóng
                    </div>
                    <div className="flex-1 text-primary cursor-pointer font-medium" onClick={onClose}>
                        Đã hiểu
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default NotiLogout;
