import React, { useEffect, useState } from "react";
import { Dropdown, Space, Table } from "antd";
import api from "@/lib/api";

const columns = [
  {
    key: "version",
    title: "Phiên bản",
    dataIndex: "version",
  },
  {
    key: "total",
    dataIndex: "total",
    title: "Số lượng",
  },
];

const Example = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      var { data: result } = await api.get("/api/users/version");
      setData(result);
    };
    loadData();
  }, []);

  const expandedRowRender = (item) => {
    const columns = [
      {
        title: "Tên",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "SĐT",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Hub",
        key: "hub",
        render: (_) => {
          return _.hub.join(",");
        },
      },
    ];
    return <Table columns={columns} dataSource={item.data} pagination={false} />;
  };
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
    />
  );
};

export default Example;
