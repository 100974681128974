import { Button, Form, Input, message } from "antd";
import { useContext, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import AppContext from "../../context/app";
import api from "@/lib/api";

export const ConfigVoip = () => {
  const [form] = Form.useForm();
  const { options } = useContext(AppContext);
  useEffect(() => {
    form.setFieldsValue({
      viettel: options?.viettel,
      vinaPhone: options?.vinaPhone,
      mobiFone: options?.mobiFone,
      other: options?.other,
    });
  }, [options]);
  const handleSave = async () => {
    const data = form.getFieldsValue();
    await api.post("/api/options", {
      ...options,
      ...data,
    });
    message.success("Save successfully");
  };
  return (
    <div className="mx-auto mt-10">
      <Form
        layout="horizontal"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 8 }}
        form={form}
        onFinish={handleSave}>
        <Form.Item name={"viettel"} label="Viettel">
          <Input.TextArea placeholder="Viettel" autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item name="vinaPhone" label="Vinaphone">
          <Input.TextArea placeholder="Vinaphone" autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item name="mobiFone" label="Mobifone">
          <Input.TextArea placeholder="Mobifone" autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item name="other" label="Other">
          <Input.TextArea placeholder="Other" autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
