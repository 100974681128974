/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "@/lib/api";

export const getHubs = createAsyncThunk("hub/fetch", async (filter) => {
  try {
    const response = await api.get("/api/hubs", { params: filter });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getHub = createAsyncThunk("hub/get", async (id) => {
  try {
    const response = await api.get(`/api/hubs/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const createHub = createAsyncThunk("hub/create", async (data) => {
  try {
    const response = await api.post("/api/hubs", data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const updateHub = createAsyncThunk("hub/update", async (data) => {
  try {
    const response = await api.put(`/api/hubs/${data.id}`, data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const deleteHub = createAsyncThunk("hub/delete", async (id) => {
  try {
    const response = await api.delete(`/api/hubs/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const getUsersHub = createAsyncThunk("hub/getUsers", async (filter) => {
  try {
    const response = await api.get(`/api/hubs/users`, { params: filter });
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const lockHub = createAsyncThunk("hub/lock", async (data) => {
  try {
    const response = await api.put(`/api/hubs/${data.id}/lock`, data);
    return response.data;
  } catch (er) {
    console.log(er)
  }
})

const initialState = {
  data: [],
  fetchLoading: false,
  pagination: {},
  creating: false,
  details: {},
};

const accountSlide = createSlice({
  name: "hub",
  initialState,
  reducers: {
    cleanup: () => {
      return initialState;
    },
    cleanupDetails: (state) => {
      state.details = {};
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHubs.pending, (state) => {
      return { ...state, fetchLoading: true };
    });
    builder.addCase(getHubs.fulfilled, (state, action) => {
      return { ...state, ...action.payload, fetchLoading: false };
    });
    builder.addCase(createHub.pending, (state, action) => {
      return { ...state, ...action.payload, creating: true };
    });
    builder.addCase(createHub.fulfilled, (state) => {
      return { ...state, creating: false };
    });
    builder.addCase(createHub.rejected, (state) => {
      return { ...state, creating: false };
    });
    builder.addCase(getHub.pending, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(getHub.fulfilled, (state, action) => {
      return { ...state, details: action.payload };
    });
    builder.addCase(updateHub.pending, (state, action) => {
      return { ...state, ...action.payload, updating: true };
    });
    builder.addCase(updateHub.fulfilled, (state) => {
      return { ...state, updating: false };
    });
    builder.addCase(updateHub.rejected, (state) => {
      return { ...state, updating: false };
    });
    builder.addCase(deleteHub.pending, (state, action) => {
      return { ...state, ...action.payload, deleting: true };
    });
    builder.addCase(deleteHub.fulfilled, (state) => {
      return { ...state, deleting: false };
    });
    builder.addCase(deleteHub.rejected, (state) => {
      return { ...state, deleting: false };
    });
    builder.addCase(getUsersHub.pending, (state) => {
      return { ...state };
    });
    builder.addCase(getUsersHub.fulfilled, (state, action) => {
      return { ...state, users: action.payload };
    });
    builder.addCase(lockHub.pending, (state) => {
      return { ...state, locking: true };
    });
    builder.addCase(lockHub.fulfilled, (state) => {
      return { ...state, locking: false };
    });
    builder.addCase(lockHub.rejected, (state) => {
      return { ...state, locking: false };
    });
  },
});

export const { cleanup, cleanupDetails } = accountSlide.actions;
export default accountSlide.reducer;
